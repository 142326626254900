<template>
  <u-checkbox
    v-if="field?.type === 'boolean' && field.attrs?.['field:type'] === 'Checkbox'"
    v-model="internalValue"
    :name="fieldKey"
    :label="label"
    v-bind="field.attrs?.elementFormGroup"
    :disabled="disabled"
  />
  <template v-if="field?.type === 'string'">
    <u-radio-group
      v-if="field.attrs?.['field:type'] === 'Radio'"
      v-model="internalValue"
      :name="fieldKey"
      :legend="label"
      :options="
        (field as SchemaString).enum?.map((e: string) => ({ label: e, value: e }))
      "
      v-bind="field.attrs?.elementFormGroup"
      :disabled="disabled"
    />
    <u-form-group
      v-else
      :name="fieldKey"
      :label="label"
      v-bind="field.attrs?.elementFormGroup"
    >
      <template
        v-if="$slots.hint"
        #hint
      >
        <slot name="hint" />
      </template>
      <u-select-menu
        v-if="
          Array.isArray((field as SchemaString).enum)
            && field.attrs?.['field:type'] === 'Select'
        "
        v-model="internalValue"
        :options="(field as SchemaString).enum"
        v-bind="field.attrs?.elementInput"
        :disabled="disabled"
      />
      <template v-else-if="field.attrs?.['field:type'] === 'Combobox'">
        <u-select-menu
          v-if="Array.isArray(field.attrs?.elementInput?.options)"
          v-model="internalValue"
          v-model:query="query"
          searchable
          searchable-placeholder="Search..."
          creatable
          show-create-option-when="always"
          :options="extendedOptions"
          v-bind="field.attrs?.elementInput"
          :disabled="disabled"
        >
          <template #option-create="{ option }">
            Use <q>{{ option }}</q> for entry.
          </template>
        </u-select-menu>
        <u-select-menu
          v-else
          v-model="internalValue"
          searchable
          searchable-placeholder="Search..."
          clear-search-on-close
          :options="(field as SchemaString).enum"
          v-bind="field.attrs?.elementInput"
          :disabled="disabled"
        />
      </template>
      <u-textarea
        v-else-if="field?.attrs?.['field:type'] === 'Textarea'"
        v-model.trim="internalValue"
        v-bind="field.attrs?.elementInput"
        :disabled="disabled"
      />
      <u-input
        v-else-if="field.format === 'date'"
        ref="el"
        v-model.trim="internalValue"
        inputmode="numeric"
        v-bind="field.attrs?.elementInput"
        :disabled="disabled"
      />
      <u-input
        v-else
        ref="el"
        v-model.trim="internalValue"
        v-bind="field.attrs?.elementInput"
        :disabled="disabled"
      />
    </u-form-group>
  </template>
  <u-form-group
    v-else-if="field?.type === 'number'"
    :name="fieldKey"
    :label="label"
    v-bind="field.attrs?.elementFormGroup"
  >
    <u-input
      v-model.number="internalValue"
      type="number"
      :min="field.minimum"
      :max="field.maximum"
      v-bind="field.attrs?.elementInput"
      :disabled="disabled"
    />
  </u-form-group>
  <template v-else-if="field?.type === 'array' && Array.isArray(internalValue)">
    <div
      v-for="index in Array.from({ length: internalValue.length }, (_, index) => index)"
      :key="index"
      class="flex flex-col gap-2"
    >
      <u-form-group :label="`Option ${index + 1}`">
        <u-input v-model.trim="internalValue[index]" />
      </u-form-group>
      <u-button
        variant="link"
        class="w-fit self-end px-1 py-0"
        @click="
          () => {
            if (!Array.isArray(internalValue)) {
              return
            }

            const newValue = internalValue.filter((_, i) => i !== index)

            if (newValue.length === 0) {
              newValue.push('')
            }

            internalValue = newValue
          }
        "
      >
        Remove
      </u-button>
    </div>
    <u-button
      class="w-fit"
      size="sm"
      :disabled="internalValue.some((o) => o.length === 0)"
      @click="
        () => {
          if (!Array.isArray(internalValue)) {
            return
          }

          internalValue.push('')
        }
      "
    >
      Add option
    </u-button>
  </template>
  <component
    :is="field?.type"
    v-else-if="field && typeof (field as SchemaUiContentful).content === 'string'"
    v-bind="field.attrs?.elementInput"
  >
    {{ (field as SchemaUiContentful).content }}
  </component>
</template>

<script lang="ts" setup>
import type {
  SchemaUiContentful,
  SchemaString,
  useForm,
} from '@tarcltd/form-vue'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
  modelValue?: string | number | boolean | string[]
  fieldKey: string
  input: ReturnType<typeof useForm>['input']
  disabled?: boolean
}>()
const emit = defineEmits(['update:modelValue'])
const field = computed(
  () => props.input.properties[props.fieldKey],
)
const label = computed(() =>
  props.input.required.includes(props.fieldKey)
    ? (field.value as SchemaString).name
    : `${(field.value as SchemaString).name} (Optional)`,
)
const internalValue = computed({
  get: () => props.modelValue,
  set(value) {
    let newValue = value

    if (typeof newValue === 'string' && newValue.length === 0) {
      newValue = undefined
    }

    if (field.value?.type === 'boolean' && typeof newValue !== 'boolean') {
      newValue = false
    }

    if (!useDeepEqual(newValue, props.modelValue)) {
      emit('update:modelValue', newValue)
    }
  },
})
const el = ref()
const query = ref('')
const extendedOptions = ref<string[]>(
  useDeepClone((field.value as SchemaString)?.attrs?.elementInput?.options ?? []),
)
const debouncedMask = useDebounceFn(mask, 10, { maxWait: 150 })

async function mask() {
  try {
    if (el.value
      && el.value.input
      && field.value?.type === 'string'
    ) {
      if (field.value.format === 'date'
        && field.value.attrs?.mask) {
        const inputMask = await import('inputmask')

        inputMask
          .default('datetime', {
            inputFormat: field.value.attrs.mask,
          })
          .mask(el.value.input)
      }
      else if (field.value.attrs?.mask) {
        const inputMask = await import('inputmask')

        inputMask.default({
          mask: field.value.attrs.mask,
        }).mask(el.value.input)
      }
    }
  }
  catch (error) {
    console.warn(`Masking failed on ${props.fieldKey}.`)
  }
}

onMounted(debouncedMask)

watch(
  field,
  (value) => {
    if (!value) {
      return
    }

    if (typeof internalValue.value === 'undefined'
      && typeof value.attrs?.default !== 'undefined') {
      internalValue.value = value.attrs.default
    }

    if (
      value.type === 'boolean'
      && typeof internalValue.value !== 'boolean'
    ) {
      internalValue.value = false
    }

    debouncedMask()
  },
  { deep: true, immediate: true },
)

watch(
  query,
  (value) => {
    extendedOptions.value = useDeepClone((field.value as SchemaString)?.enum ?? [])

    if (value.length > 0) {
      extendedOptions.value.push(value)
    }
  },
  { immediate: true },
)
</script>
